import { Route, Routes, useLocation } from 'react-router-dom';
import LogInForm from './Forms/LogInForm';
import SignUpForm from './Forms/SignUpForm';
import ForgotPasswordForm from './Forms/ForgotPasswordForm';
import Home from '../../pages/Home';
import Article from '../../pages/Article';
import './styles.css';
import Navbar from '../../components/Navbar';

const Unauthenticated = ({isUserAuthenticated, userGroup, logIn, logOut }) => {
  const location = useLocation();

  const hideNavbar = () => {
    const hidePaths = ['/log-in', '/sign-up', '/forgot-password'];
    return hidePaths.includes(location.pathname);
  };

  const renderNavbar = () => {
    return <Navbar 
      isUserAuthenticated={isUserAuthenticated} 
      userGroup={userGroup} 
      logOut={logOut} 
    />
  }

  return (
    <>
      {!hideNavbar() && renderNavbar()}
      <div className="unauthenticated-container"> 
        <Routes>
          <Route path="*" element={<Home isAuthenticated={false} />} />
          <Route path="/log-in" element={<LogInForm logIn={logIn} />} />
          <Route path="/sign-up" element={<SignUpForm />} /> 
          <Route path="/forgot-password" element={<ForgotPasswordForm />} />
          <Route path="/articles/:type/:title" element={<Article />} />
        </Routes>
      </div>
    </>
  );
};

export default Unauthenticated;
