import './styles.css';
import Input from '../../../components/FormElements/Input';
import Button from '../../../components/FormElements/Button';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { confirmSignUp }  from 'aws-amplify/auth';

function VerifyEmailForm({ email }) {
  const navigate = useNavigate();
 
  const [verifyEmailValues, setVerifyEmailValues] = useState({ username: email, confirmationCode: '' });
  
  const [confirmationCodeTouched, setConfirmationTouched] = useState(false);
  const [confirmationCodeError, setConfirmationCodeError] = useState('');
  
  const [verifyEmailFormError, setVerifyEmailFormError] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  
  const updateVerifyEmailFormData = (e) => {
    const { name, value } = e.target;
    setVerifyEmailValues({ ...verifyEmailValues, [name]: value });

    if(name === 'confirmationCode'){
      setConfirmationTouched(true);
    }
  };
  
  useEffect(() => {

    if (confirmationCodeTouched) {
      const { confirmationCode } = verifyEmailValues;
  
      if (confirmationCode === '') {
        setConfirmationCodeError("Verification code is required");
      } else {
        setConfirmationCodeError('');
      }
    }

  }, [verifyEmailValues, confirmationCodeTouched]);  

  const processVerifyEmail = async (e) => {
    e.preventDefault();
    setConfirmationCodeError('');
    const { username, confirmationCode } = verifyEmailValues;
    
    setIsLoading(true);
    
    try{
      
      if(confirmationCode === ''){
        setIsLoading(false);
        setConfirmationCodeError("Verification code is required");
        return;
      }

      var confirmSignUpStatus = await confirmSignUp({ username,confirmationCode });
      if (confirmSignUpStatus.isSignUpComplete) {
        setIsLoading(false);
        // Check if the current route is '/log-in'
        if (window.location.pathname === '/log-in') {
            // Reload the page if already on the '/log-in' route
            window.location.reload();
        } else {
            // Redirect to '/log-in' if not already on that route
            navigate('/log-in');
        }
    }     
    }catch(error){
      console.log(error);
      switch(error.name){
        case "EmptyConfirmSignUpCode":
          setIsLoading(false);
          setConfirmationCodeError("Please enter a verification code");
          break;
        case "CodeMismatchException":
          setIsLoading(false);
          setConfirmationCodeError("Invalid verification code");
          break;
        case "ExpiredCodeException":
          setIsLoading(false);
          setConfirmationCodeError("Expired verification code");
          break;
        default:
          setIsLoading(false);
          setVerifyEmailFormError("Error occurred, please try again later")
          console.log(error);
          break;
      }
    }
  }
 
  return (
    <div className="form-container">
    <form className="form" onSubmit={processVerifyEmail}>
      <label className="form-title">Verify Email</label>
      <Input 
        name="confirmationCode"
        placeholder="Enter your verification code"
        type="input"
        value={verifyEmailValues.confirmationCode}
        onChange={updateVerifyEmailFormData}
        error={confirmationCodeError}
      />
      <div className="button-container">
          <Button text="Verify" isLoading={isLoading}></Button>
      </div>
      {verifyEmailFormError && (
          <div className="error-message">
            <div className="error-text">{verifyEmailFormError}</div>
          </div>
       )}
    </form>
  </div>
  );
}

export default VerifyEmailForm;
