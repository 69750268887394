import React, { useState, useEffect } from 'react';
import './styles.css';
import { toast } from 'react-toastify';
import { fetchArticles } from '../../../../services/articleService';
import { generateChatGPTArticle } from '../../../../services/articleService';
import ManageArticlePane from './ManageArticlePane';
import Button from '../../../../components/FormElements/Button'

const ManageArticles = () => {

  const [articles, setArticles] = useState([]);
  const [articlesPendingApproval, setArticlesPendingApproval] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    loadArticles();
  }, []);

  const loadArticles = async () => {
    try{
      const articlesPendingApprovalFromAPI = await fetchArticles(false);
      const articlesFromAPI = await fetchArticles(true);
      setArticles(articlesFromAPI);
      setArticlesPendingApproval(articlesPendingApprovalFromAPI);

    }catch(error){
      console.error('Error loading articles:', error);
    }
  };

  const addArticle = async () => {
    setIsLoading(true); // Start loading

    var added = await generateChatGPTArticle();

    if(added){
      toast.success("Article added!");
      loadArticles();
      setIsLoading(false); 
    }else{
      toast.error("Article could not be added");
      setIsLoading(false); 
    }
  }

  return (
  <div className="container">
    <div className="manage-articles">
        <div className="manage-articles-controls">
          <Button text="Article" onClick={addArticle} isLoading={isLoading} disabled={isLoading} addIcon={true}></Button>
        </div>
        <div className="articles">
          <label className="article-status-label">Pending</label>
            <div className="article-list-container"> 
                {articlesPendingApproval.map(article => (
                  <ManageArticlePane key={article.id} article={article} imageUrl={article.imageUrl} />
                ))}
            </div>
        </div>
        <div className="articles">
            <label className="article-status-label">Active</label>
            <div className="article-list-container"> 
                {articles.map(article => (
                  <ManageArticlePane key={article.id} article={article} imageUrl={article.imageUrl} />
                ))}
            </div>
        </div>
      </div> 
    </div>
  );
}

export default ManageArticles;
