import React from 'react';
import './styles.css';
import { useNavigate } from 'react-router-dom';

const ArticlePane = ({ article, imageUrl }) => {

  const navigate = useNavigate();

  const handleImageClick = () => {
    navigate(`/articles/${article.type}/${article.searchTitle}`);
  };

  const formatDate = (dateString) => {
    const options = { month: 'short', day: 'numeric', year: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const formattedDate = formatDate(article.createdAt);
  return (
    <div className="article-pane" style={{ backgroundImage: `url(${imageUrl})` }}>
      <div className="article-overlay" onClick={handleImageClick}>
        <h3>{article.title}</h3>
        <p>{formattedDate}</p>
      </div>
    </div>
  );
};

export default ArticlePane;
