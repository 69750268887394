import './styles.css';
import Input from '../../../components/FormElements/Input';
import Button from '../../../components/FormElements/Button';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { resetPassword }  from 'aws-amplify/auth';
import { confirmResetPassword }  from 'aws-amplify/auth';
import logo from '../../../Transparent_Logo.svg';

function ForgotPasswordForm() {
  const navigate = useNavigate();
   
  const [userCredentials, setUserCredentials] = useState({ username: '' });
  const [resetPasswordCredentials, setResetPasswordCredentials] = useState({ confirmationCode: '', newPassword: '', confirmPassword: ''});
  
  const [forgotPasswordFormError, setForgotPasswordFormError] = useState('');
  const [resetPasswordFormError, setResetPasswordFormError] = useState('');

  const [emailTouched, setEmailTouched] = useState(false);
  const [emailError, setEmailError] = useState('');

  const [confirmationCodeTouched, setConfirmationTouched] = useState(false);
  const [confirmationCodeError, setConfirmationCodeError] = useState('');

  const [passwordTouched, setPasswordTouched] = useState(false);
  const [passwordError, setPasswordError] = useState('');

  const [confirmPasswordTouched, setConfirmPasswordTouched] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState('');

  const [showResetForm, setShowResetForm] = useState(false); 

  const [isLoading, setIsLoading] = useState(false);
  
  const updateForgotPasswordFormData = (e) => {
    const { name, value } = e.target;
    setUserCredentials({ ...userCredentials, [name]: value });

    if(name === 'username'){
      setEmailTouched(true);
    }
  }; 

  const processForgotPassword = async (e) => {
    e.preventDefault();
    setForgotPasswordFormError('');
    setEmailError('');
    
    const { username } = userCredentials;
    
    setIsLoading(true);
    
    try {

      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  
      if(username === ''){
        setIsLoading(false);
        setEmailError("Email address is required");
        return;
      }else if(!emailRegex.test(username)) {
        setIsLoading(false);
        setEmailError("Email address is invalid");
        return;
      }

      if (await resetPassword({ username })) {
        setIsLoading(false);
        setEmailTouched(false);
        setShowResetForm(true); // Show the reset password form
      }
    } catch (error) {
      switch(error.name){
        case "LimitExceededException":
          setIsLoading(false);
          setForgotPasswordFormError("Request limit exceeded, please try again later");
          break;
        case "EmptyResetPasswordUsername":
          setIsLoading(false);
          setEmailError("Email address is required");
          break;
        case "UserNotFoundException": 
          // setShowResetForm(true);
          setIsLoading(false);
          break;
        default:
          setIsLoading(false);
          break;
      }
    }
  };

  const updateResetPasswordFormData = (e) => {
    const { name, value } = e.target;
    setResetPasswordCredentials({ ...resetPasswordCredentials, [name]: value });
  
    if(name === 'confirmationCode'){
      setConfirmationTouched(true);
    }

    if (name === 'newPassword') {
      setPasswordTouched(true);
    }

    if(name === 'confirmPassword'){
      setConfirmPasswordTouched(true);
    }
  };  

  useEffect(() => {

    if (emailTouched) {
      const { username } = userCredentials;
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      
      if(username === ''){
        setEmailError("Email address is required");
      }else if(!emailRegex.test(username)) {
        setEmailError("Email address is invalid");
      }else{
        setEmailError('');
      }
    }

    if (confirmationCodeTouched) {
      const { confirmationCode } = resetPasswordCredentials;
  
      if (confirmationCode === '') {
        setConfirmationCodeError("Verification code is required");
      } else {
        setConfirmationCodeError('');
      }
    }
    
    if (passwordTouched) {
      const { newPassword } = resetPasswordCredentials;     
      const passwordRegex = /^(?=.*[0-9])(?=.*[a-zA-Z]).{8,}$/;
      
      if (!passwordRegex.test(newPassword)) {
        setPasswordError("Min. of 8 characters, 1 letter, 1 number");
      } else {
        setPasswordError('');
      }
    }

    if (confirmPasswordTouched) {
      const { newPassword, confirmPassword } = resetPasswordCredentials

      if (newPassword !== confirmPassword) {
        setConfirmPasswordError("Passwords do not match");
      } else {
        setConfirmPasswordError('');
      }
    }

  }, [userCredentials, resetPasswordCredentials, emailTouched, confirmationCodeTouched, passwordTouched, confirmPasswordTouched]);  

  const processResetPassword = async (e) => {
    e.preventDefault();
    setResetPasswordFormError('');
    setConfirmationCodeError('');
    setPasswordError('');
    setConfirmPasswordError('');
    
    const { confirmationCode, newPassword, confirmPassword } = resetPasswordCredentials;
    const { username } =  userCredentials;

    setIsLoading(true);

    try {
      var error = false;

      if(confirmationCode === ''){
        setConfirmationCodeError("Verification code is required");
        error = true;
      }
      
      const passwordRegex = /^(?=.*[0-9])(?=.*[a-zA-Z]).{8,}$/;
      if (!passwordRegex.test(newPassword)) {
        setPasswordError("Min. of 8 characters, 1 letter, 1 number");
        error = true;
      }

      if (newPassword !== confirmPassword) {
        setConfirmPasswordError("Passwords do not match");
        error = true;
      }

      if(error){
        setIsLoading(false);
        return;
      }
      
      var confirmReset = await confirmResetPassword({ username ,confirmationCode, newPassword });
      
      if (confirmReset === undefined) {
        setIsLoading(false);
        navigate('/log-in'); 
      }
    } catch (error) {
      switch(error.name){
        case "LimitExceededException":
          setIsLoading(false);
          setResetPasswordFormError("Request limit exceeded, please try again later");
          break;
        case "ExpiredCodeException":
          setIsLoading(false);
          setConfirmationCodeError("Expired confirmation code");
          break;
        case "CodeMismatchException":
          setIsLoading(false);
          setConfirmationCodeError("Invalid verification code");
          break;
        case "InvalidParameterException":
          setIsLoading(false);
          setConfirmationCodeError("Invalid verification code");
          //console.log(error);
          break;
        case "EmptyResetPasswordUsername":
          setIsLoading(false);
          break;
        default:
          break;
      }
    }
  };

  const renderResetPasswordForm = () => (
    <div className="form-container">
      <form className="form" onSubmit={processResetPassword}>
        <label className="form-title">Reset Password</label>
        <Input 
          name="confirmationCode"
          placeholder="Enter your verification code"
          type="input"
          value={resetPasswordCredentials.confirmationCode}
          onChange={updateResetPasswordFormData}
          error={confirmationCodeError}
        />
        <Input 
          name="newPassword"
          placeholder="Enter your new password"
          type="password"
          value={resetPasswordCredentials.newPassword}
          onChange={updateResetPasswordFormData}
          error={passwordError}
        />
         <Input 
          name="confirmPassword"
          placeholder="Re-enter your new password"
          type="password"
          value={resetPasswordCredentials.confirmPassword}
          onChange={updateResetPasswordFormData}
          error={confirmPasswordError}
        />
        <div className="button-container">
            <Button text="Reset" isLoading={isLoading}></Button>
        </div>
        {resetPasswordFormError && (
            <div className="error-message">
              <div className="error-text">{resetPasswordFormError}</div>
              {/* <button className="close-error" onClick={() => setForgotPasswordError('')}>
                &#x2715; 
              </button> */}
            </div>
         )}
      </form>
    </div>
  );

  return (
    <div class="authentication-container">
      <div className="logo-container">
          <img src={logo} alt="Logo" className="logo" />
          <div className="logo-title">SHARKPICK</div>
      </div>
      {!showResetForm ? (
        <div className="form-container">
        <form className="form" onSubmit={processForgotPassword}>
           <label className="form-title">Forgot Password</label>
           <Input 
             name="username"
             placeholder="Enter your email address"
             value={userCredentials.username}
             onChange={updateForgotPasswordFormData}
             error={emailError}
           />
           <div className="button-container">
                <Button text="Submit" isLoading={isLoading}></Button>
           </div>
           {forgotPasswordFormError && (
            <div className="error-message">
              <div className="error-text">{forgotPasswordFormError}</div>
              {/* { <button className="close-error" onClick={() => setForgotPasswordError('')}>
                &#x2715; 
              </button> } */}
            </div>
            )}
         </form>
       </div>
      ) : (
        // Render the Reset Password Form
        renderResetPasswordForm()
      )}
    </div>
  );
}

export default ForgotPasswordForm;
