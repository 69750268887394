import React from 'react';
import { useNavigate } from 'react-router-dom';
import './styles.css';

const ManageArticlePane = ({ article, imageUrl }) => {

  const navigate = useNavigate();

  const handleImageClick = () => {
    if(!article.approved){
      navigate(`/manage/articles/${article.type}/${article.searchTitle}`);
    }else{
      navigate(`/manage/articles/${article.type}/${article.searchTitle}`);
    }
  };

  // Will need for add article titleSearch
  // function slugify(text) {
  //   return text.toLowerCase()
  //              .replace(/\s+/g, '-')     // Replace spaces with -
  //              .replace(/[^\w-]+/g, '')  // Remove all non-word chars
  //              .replace(/--+/g, '-')     // Replace multiple - with single -
  //              .replace(/^-+/, '')       // Trim - from start of text
  //              .replace(/-+$/, '');      // Trim - from end of text
  // }  


  const formatDate = (dateString) => {
    const options = { month: 'short', day: 'numeric', year: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };
  
  const formattedDate = formatDate(article.createdAt);
  return (
    <div className="article-pane" style={{ backgroundImage: `url(${imageUrl})` }}>
      <div className="article-overlay" onClick={handleImageClick}>
        <h3>{article.title}</h3>
        <p>{formattedDate}</p>
      </div>
    </div>
  );
};

export default ManageArticlePane;
