/* eslint-disable */
// this is an auto generated file. This will be overwritten


export const generateArticle = /* GraphQL */ `
  query GenerateArticle {
    generateArticle {
      statusCode
      body
      __typename
    }
  }
`;

export const getArticle = /* GraphQL */ `
  query GetArticle($id: ID!) {
    getArticle(id: $id) {
      id
      type
      title
      searchTitle
      article
      author
      image
      imageUrl
      approved
      approvedBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listArticles = /* GraphQL */ `
  query ListArticles(
    $id: ID
    $filter: ModelArticleFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listArticles(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        type
        title
        searchTitle
        article
        author
        image
        imageUrl
        approved
        approvedBy
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const articleBySearchTitle = /* GraphQL */ `
  query ArticleBySearchTitle(
    $searchTitle: String!
    $sortDirection: ModelSortDirection
    $filter: ModelArticleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    articleBySearchTitle(
      searchTitle: $searchTitle
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        title
        searchTitle
        article
        author
        image
        imageUrl
        approved
        approvedBy
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
