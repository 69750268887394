import {Route, Routes} from 'react-router-dom';
import './styles.css'
import Home from '../../pages/Home';
import Article from '../../pages/Article';
import Navbar from '../../components/Navbar';

const Authenticated = ( {isUserAuthenticated, userGroup, logOut} ) => {
  
  const renderNavbar = () => {
    return <Navbar 
      isUserAuthenticated={isUserAuthenticated} 
      userGroup={userGroup} 
      logOut={logOut} 
    />
  }

  return (
    <>
      {renderNavbar()}
      <div className="authenticated-container">   
          <Routes>
            <Route path="*" element={<Home isAuthenticated={true} logOut={logOut} />} />
            <Route path="/articles/:type/:title" element={<Article />} />
          </Routes>
      </div>
    </>
  );
};

export default Authenticated;