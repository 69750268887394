import { generateClient } from 'aws-amplify/api';
import { createArticle, updateArticle, deleteArticle } from '../graphql/mutations';
import { listArticles, articleBySearchTitle, generateArticle  } from '../graphql/queries';
import { getUrl } from 'aws-amplify/storage';
import { list } from 'aws-amplify/storage';

const client = generateClient();

export async function fetchArticle(searchTitle){
    const apiData =  await client.graphql({ query: articleBySearchTitle, variables: { searchTitle: searchTitle, limit: 1 }});
    const articleFromAPI = apiData.data.articleBySearchTitle;
    const article = articleFromAPI.items[0];

    if(article === undefined){
        return;
    }

    if (article.image) {
        var image = await getUrl({
            key: article.image
        });
        article.imageUrl = image.url.toString();
    } else {
        var defaultImage = await getUrl({
            key: "default.png"
        });
        article.imageUrl = defaultImage.url.toString();
    }
    
    return article;
}


export async function fetchArticles(approved) {
    const apiData = await client.graphql({ query: listArticles, variables: { filter: { approved: { eq: approved }}} });
    const articlesFromAPI = apiData.data.listArticles.items;
    // Sort articles by createdAt in descending order (newest first)
    articlesFromAPI.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

    // Sort articles by createdAt in ascending order (oldest first)
    // articlesFromAPI.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));

    await Promise.all(
        articlesFromAPI.map(async (article) => {
          if (article.image) {
            var image = await getUrl({
                key: article.image
            });
            article.imageUrl = image.url.toString();
          }else{
            var defaultImage = await getUrl({
                key: "default.png"
            });
            article.imageUrl = defaultImage.url.toString();
          }
          return article;
        })
      );
    return articlesFromAPI;
}


export async function articleTitleIsUnique(searchTitle){
    const existingArticle = await fetchArticle(searchTitle);
    if (existingArticle) {
        return false; 
    }else{
        return true;
    }
}

export async function addBlankArticle(){

    let initialTitle = "DFS Breakdown";
    let initialSearchTitle = "dfs-breakdown";
    let count = 1; 

    const articleInput = {
        type: "nfl",
        title: "DFS Breakdown",
        searchTitle: "dfs-breakdown",
        article: "blank article",
        author: "Alexander Robinson",
        image: "default.png",
        approved: false,
        approvedBy: null
    };

    try {
        let existingArticle;
        do {
            existingArticle = await fetchArticle(articleInput.searchTitle);
            if (existingArticle) {
                articleInput.title = initialTitle + " " + count;
                articleInput.searchTitle = initialSearchTitle + '-' + count;
                count++;
            }
        } while (existingArticle);

        // If no existing article with the modified title, proceed to add a new article
        const newArticle = await client.graphql({
            query: createArticle,
            variables: { input: articleInput }
        });
        console.log('Article added:', newArticle);
        return true;
    } catch (error) {
        console.error('Error adding article:', error);
        return false;
    }
}

export async function generateChatGPTArticle(){
    try{
        const createArticle = await client.graphql({
            query: generateArticle
        });
        console.log('Article created:', createArticle);
        return true;
    }catch(error){
        console.error('Error creating article:', error);
        return false;
    }
}

export async function updateArticleContent(article){
    const updateArticleInput = {
        id: article.id, 
        approved: article.approved,
        approvedBy: null, 
        article: article.article,
        author: article.author, 
        image: article.image,
        imageUrl: null, 
        title: article.title,
        searchTitle: article.searchTitle,
        type: article.type
    };
    try {
        const updatedArticle = await client.graphql({
            query: updateArticle, 
            variables: { input: updateArticleInput },
        });
        console.log('Article updated:', updatedArticle);
    } catch (error) {
        console.error('Error updating article:', error);
    }
}

export async function deleteArticleById(articleId) {
    const articleDeleteInput = {
        id: articleId, // ID of the article to update
    };

    try {
        const deletedArticle = await client.graphql({
            query: deleteArticle, 
            variables: { input: articleDeleteInput },
        });
        console.log('Article deleted:', deletedArticle);
    } catch (error) {
        console.error('Error updating article:', error);
    }
}

export async function approveArticleById(articleId){
    const articleUpdateInput = {
        id: articleId, // ID of the article to update
        approved: true, // New content for the article column   
    };

    try {
        const updatedArticle = await client.graphql({
            query: updateArticle, 
            variables: { input: articleUpdateInput },
        });
        console.log('Article updated:', updatedArticle);
    } catch (error) {
        console.error('Error updating article:', error);
    }
}

export async function fetchImages() {
    try {
        const images = await list('public/');     

        const imageDetails = await Promise.all(
            images.items
                // .filter(image => image.key !== 'default.png')  //filter out default image
                .map(async (image) => {
                    const imageUrl = await getUrl({ key: image.key });
                    return {
                        key: image.key,
                        url: imageUrl.url.toString()
                    };
                })
        );

        return imageDetails;
    } catch (error) {
        console.error("Error fetching images:", error);
        return [];
    }
}