import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { fetchArticle } from '../../../../services/articleService';
import { updateArticleContent } from '../../../../services/articleService';
import { toast } from 'react-toastify';
import { approveArticleById } from '../../../../services/articleService';
import { deleteArticleById } from '../../../../services/articleService';
import ConfirmationModal from '../../../../components/ConfirmationModal';
import ImageSelector from './ImageSelector';
import { articleTitleIsUnique } from '../../../../services/articleService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import './styles.css';
import Button from '../../../../components/FormElements/Button'

const ManageArticle = () => {
  const { title } = useParams(); 

  const types = ['nfl', 'mlb', 'nba', 'golf', 'tennis', 'nascar'];

  const navigate = useNavigate();
    
  const [article, setArticle] = useState(null);
  const [articleType, setArticleType] = useState('');
  const [articleTitle, setArticleTitle] = useState('');
  const [articleAuthor, setArticleAuthor] = useState('');
  const [paragraphs, setParagraphs] = useState([]);
  const [currentImage, setCurrentImage] = useState('');

  const [status, setStatus] = useState('');

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState('');

  const [lastAction, setLastAction] = useState(''); 

  // const formatDate = (dateString) => {
  //     const options = { month: 'short', day: 'numeric', year: 'numeric' };
  //     return new Date(dateString).toLocaleDateString(undefined, options);
  // };

  const loadArticle = async (searchTitle) => {
      searchTitle = `${searchTitle}`; 
      try {
        const articleData = await fetchArticle(searchTitle);
        setArticle(articleData);
        setArticleType(articleData.type);
        setArticleTitle(articleData.title);
        setArticleAuthor(articleData.author);
        setParagraphs(articleData.article.split('\n\n'));
        setStatus(articleData.approved ? 'active' : 'pending');
        // setFormattedDate(formatDate(articleData.createdAt));
        var image = {
          key: articleData.image,
          url: articleData.imageUrl
        };
        setCurrentImage(image);
      } catch (error) {
          console.error("Error fetching article", error);
      }
  };

  function slugify(text) {
    return text.toLowerCase()
               .replace(/\s+/g, '-')     // Replace spaces with -
               .replace(/[^\w-]+/g, '')  // Remove all non-word chars
               .replace(/--+/g, '-')     // Replace multiple - with single -
               .replace(/^-+/, '')       // Trim - from start of text
               .replace(/-+$/, '');      // Trim - from end of text
  } 

  const handleSaveArticle = async () => {
    setModalContent('Are you sure you want to edit this article?');
    setLastAction('edit');
    setIsModalOpen(true);
  };
 
  const handleApproveArticle = () => {
    setModalContent('Are you sure you want to approve this article?');
    setLastAction('approve'); 
    setIsModalOpen(true);
  };

  const handleDenyArticle = () => {
    setModalContent('Are you sure you want to deny this article? This action will permanetly delete it.');
    setLastAction('deny'); 
    setIsModalOpen(true);
  };

  const handleArticleTypeChange = (event) => {
    setArticleType(event.target.value);
  };

  async function validateEditArticle(){

    // Ensure the title is not empty
    if(articleTitle.trim() === ''){
      toast.error("Title is required");
      return false;
    }

    var searchTitle = slugify(articleTitle);

    // Check for title uniqueness if it has been changed
    if(article.searchTitle !== searchTitle){
      if(!(await articleTitleIsUnique(searchTitle))){
        toast.error("Article title already exists");
        return false;
      }
    }

    // Additional validations for active articles
    if(status === 'active'){

      // Validate the presence of an author
      if(articleAuthor.trim() === ''){
        toast.error("Author is required");
        return false;
      }

      // Ensure there are at least two non-empty paragraphs
      const nonEmptyParagraphs = paragraphs.filter(paragraph => paragraph.trim() !== '');
      if(nonEmptyParagraphs.length < 2){
        toast.error("Article must have at least two paragraphs");
        return false;
      }

      // Check for the presence and validity of the article image
      if(!currentImage){
        toast.error("Article image is required");
        return false;
      }
  
      if(currentImage.key === 'default.png'){
        toast.error("Article image cannot be default");
        return false;
      }
    }

    return true;
  }

  async function validateApproveArticle() {

    // Ensure the title is not empty
    if(articleTitle.trim() === ''){
      toast.error("Title is required");
      return false;
    }

    var searchTitle = slugify(articleTitle);

    // Check for title uniqueness if it has been changed
    if(article.searchTitle !== searchTitle){
      if(!(await articleTitleIsUnique(searchTitle))){
        toast.error("Article title already exists");
        return false;
      }
    }
    
    // Validate the presence of an author
    if(articleAuthor.trim() === ''){
      toast.error("Author is required");
      return false;
    }

    // Ensure there are at least two non-empty paragraphs
    const nonEmptyParagraphs = paragraphs.filter(paragraph => paragraph.trim() !== '');
    if(nonEmptyParagraphs.length < 2){
      toast.error("Article must have at least two paragraphs");
      return false;
    }

    // Check for the presence and validity of the article image
    if(!currentImage){
      toast.error("Article image is required");
      return false;
    }

    if(currentImage.key === 'default.png'){
      toast.error("Article image cannot be default");
      return false;
    }
    return true;
  }

  const confirmAction = async () => {

    var isValid;
    var updatedSearchTitle;

    switch (lastAction) {
      case 'edit':    
      
        isValid = await validateEditArticle();

        if(isValid){

          updatedSearchTitle = slugify(articleTitle);
        
          const updatedArticle = {
            ...article,
            searchTitle: updatedSearchTitle,
            type: articleType.toLowerCase(),
            title: articleTitle,
            author: articleAuthor,
            article: paragraphs.filter(paragraph => paragraph.trim() !== '').join('\n\n')
          };
  
          try {
            await updateArticleContent(updatedArticle);
            toast.success("Article updated!");
            navigate('/manage/articles');
          } catch (error) {
            console.error("Error editing article: ", error);
          }
        }
        break;
      case 'approve':
        isValid = await validateApproveArticle();

        if(isValid){

          updatedSearchTitle = slugify(articleTitle);
        
          const updatedArticle = {
            ...article,
            searchTitle: updatedSearchTitle,
            type: articleType.toLowerCase(),
            title: articleTitle,
            author: articleAuthor,
            article: paragraphs.filter(paragraph => paragraph.trim() !== '').join('\n\n')
          };

          try {
            await updateArticleContent(updatedArticle);
            await approveArticleById(article.id);
            toast.success("Article approved!");
            navigate('/manage/articles');
          } catch (error) {
            console.error("Error approving article: ", error);
          }
        }
        break;
      case 'deny':
        try {
          await deleteArticleById(article.id);
          toast.success("Article denied!");
          navigate('/manage/articles');
        } catch (error) {
          console.error("Error denying article: ", error);
        }
        break;
      default:
        console.log("No action specified");
    }
    setIsModalOpen(false);
  };

  const cancelAction = () => {
    setIsModalOpen(false);
  };

  const handleParagraphChange = (index, newText) => {
    const updatedParagraphs = paragraphs.map((paragraph, idx) => idx === index ? newText : paragraph);
    setParagraphs(updatedParagraphs);
  };

  const handleAddParagraph = () => {
    setParagraphs([...paragraphs, '']);
  };

  const handleDeleteParagraph = (index) => {
    setParagraphs(paragraphs.filter((_, idx) => idx !== index));
  };

  const handleImageUpdate = (newImage) => {
    if (article) {
      setArticle({ ...article, image: newImage.key });
      setCurrentImage(newImage);
    }
  };

  useEffect(() => {
    loadArticle(title);
  }, [title]);

  if (!article) {
      return <div className="loading-article">Loading...</div>;
  }

  return (
  <div className="manage-article-wrapper">
    <div className="manage-article">
      <div className="form-header">
        {status !== 'active' && (
          <div className="approval-actions">
            <Button text="Approve" onClick={handleApproveArticle} size="small"></Button>
            <Button text="Deny" className="cancel-btn" onClick={handleDenyArticle} size="small"></Button>
          </div>
        )}
      </div>
      <div className="article-edit">
      <select 
          className="article-type-select"
          value={articleType}
          onChange={handleArticleTypeChange}
        >
          {types.map(type => (
            <option key={type} value={type}>{type.toUpperCase()}</option>
          ))}
        </select>
        <input 
          type="text"
          className="article-title-input"
          placeholder="Article Title"
          value={articleTitle}
          onChange={(e) => setArticleTitle(e.target.value)}
        />
        <input 
          type="text"
          className="article-author-input"
          placeholder="Author Name"
          value={articleAuthor}
          onChange={(e) => setArticleAuthor(e.target.value)}
        />
        <div className="paragraphs-container">
          {paragraphs.map((paragraph, index) => (
            <div key={index} className="paragraph-edit">
              <textarea
                className="paragraph-textarea"
                placeholder={`Paragraph ${index + 1}`}
                value={paragraph}
                onChange={(e) => handleParagraphChange(index, e.target.value)}
              />
              <button className="delete-paragraph-btn" onClick={() => handleDeleteParagraph(index)}>
                X
              </button>
            </div>
          ))}
        </div>
        <div className="article-actions">
          <span onClick={handleAddParagraph}>
            <FontAwesomeIcon icon={faPlusCircle} className="icon-style" />
            <span className="text-style">Paragraph</span>
          </span>
        </div>
        <ImageSelector
            currentImage={currentImage}
            articleId={article?.id}
            onImageUpdate={handleImageUpdate}
          />
      </div>
      <div className="update-actions">
          <Button text="Save Changes" onClick={handleSaveArticle} size="small"></Button>
        </div>
      <ConfirmationModal 
        isOpen={isModalOpen}
        content={modalContent}
        onConfirm={confirmAction}
        onCancel={cancelAction}
      />
    </div>   
  </div>
  );
}

export default ManageArticle;
