import React, { useState, useEffect } from 'react';
import './styles.css';
import { useParams } from 'react-router-dom';
import { fetchArticle } from '../../services/articleService';

const Article = () => {
    const { title } = useParams(); // Extract title from the URL
    
    // State for storing the fetched article and formatted date
    const [article, setArticle] = useState(null);
    const [formattedDate, setFormattedDate] = useState('');

    const formatDate = (dateString) => {
        const options = { month: 'short', day: 'numeric', year: 'numeric' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    const loadArticle = async (titleForSearch) => {
        titleForSearch = `${titleForSearch}`; 
        try {
            const articleData = await fetchArticle(titleForSearch, true);
            setArticle(articleData);
            setFormattedDate(formatDate(articleData.createdAt));
        } catch (error) {
            console.error("Error fetching article", error);
        }
    };

    // useEffect to call fetchArticle when the component mounts or title changes
    useEffect(() => {
        loadArticle(title);
    }, [title]);

    if (!article) {
      return <div className="loading-article">Loading...</div>;
   }

    return (
    <div className="article-wrapper">
        <div className="article">
            <div className="article-content">
                <div className="article-header">
                    <h1>{article.title}</h1>
                    <p className="article-date">{formattedDate}</p>
                </div>
                <div className="article-author">
                    <p>By {article.author}</p> 
                </div>
                <div className="article-body">
                    {article.article.split('\n\n').map((paragraph, index) => (
                        <p key={index}>{paragraph}</p>
                    ))}
                </div>
            </div>
        </div>
    </div>
  );
};

export default Article;