import React from 'react';
import Button from '../../components/FormElements/Button'
import './styles.css'; 

const ConfirmationModal = ({ isOpen, content, onConfirm, onCancel }) => {
  if (!isOpen) return null;

  return (
    <div className="confirmation-modal-backdrop">
      <div className="confirmation-modal">
        <p className="confirmation-modal-content">{content}</p>
        <div className="confirmation-modal-actions">
          <Button text="Confirm" size="small" onClick={onConfirm}></Button>
          <Button text="Cancel" className="cancel-btn" size="small" onClick={onCancel}></Button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
