import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getCurrentUser }  from 'aws-amplify/auth';
import { fetchAuthSession }  from 'aws-amplify/auth';
import { signIn }  from 'aws-amplify/auth';
import { signOut }  from 'aws-amplify/auth';
import Unauthenticated from '../../layouts/Unauthenticated';
import Authenticated from '../../layouts/Authenticated';
import Admin from '../../layouts/Admin';
import './styles.css'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {

  const navigate = useNavigate();

  const [isUserAuthenticated, setIsUserAuthenticated] = useState(false);
  const [userGroup, setUserGroup] = useState(null);

  const checkUserAuthentication = async () => {
      try {
        await getCurrentUser();
        
        setIsUserAuthenticated(true);
        
        await checkUserGroup();

      } catch (error) {
        setIsUserAuthenticated(false);
        setUserGroup(null);
      } 
  } 

  const checkUserGroup = async() => {
    try {  
      var session = await fetchAuthSession();           
      const groups = session.tokens.accessToken.payload["cognito:groups"];
      if (groups && groups.includes('Admin')) {
        setUserGroup('admin');
      }
    } catch (error) {
      console.log("Error checking user group: ", error);
    } 
  }

  useEffect(() => {
    checkUserAuthentication();
  }, []);

  const logOut = async() => {
    try{
      await signOut();
      navigate('/');
      setIsUserAuthenticated(false);
      setUserGroup(null);
    } catch( error){
      console.log("Error signing out: ", error);
    }
  }

  const logIn = async ({username, password}) => {
    try {
      var signInStatus = await signIn({username, password});
      if(signInStatus.isSignedIn === true){
        setIsUserAuthenticated(true);
        await checkUserGroup();
        return 'SUCCESS';
      }else{
        if(signInStatus.nextStep.signInStep === 'CONFIRM_SIGN_UP'){
          return 'CONFIRM';
        }
        return 'FAILURE';
      }
    } catch (error) {
      console.log("Error during login: ", error);
      switch(error.name){
        case "LimitExceededException":
          return 'FAILURE';
        case "EmptySignInUsername":
          return 'FAILURE';
        case "EmptySignInPassword":
          return 'FAILURE';
        case "UserNotFoundException": 
          return 'FAILURE';
        case "NotAuthorizedException":
          return 'FAILURE';
        default:
          return 'FAILURE';
      }
    }
  };

  const renderToast = () => {
    return <ToastContainer position="bottom-left"/>;
  }

  const renderLayout = () => {
    if(isUserAuthenticated){
       if(userGroup === 'admin'){
          return <Admin isUserAuthenticated={isUserAuthenticated} userGroup={userGroup} logOut={logOut} /> 
       }else{
          return <Authenticated isUserAuthenticated={isUserAuthenticated} userGroup={userGroup} logOut={logOut} /> 
       }
    }else{
      return <Unauthenticated isUserAuthenticated={isUserAuthenticated} userGroup={userGroup} logIn={logIn} logOut={logOut} />
    }
  };

  return (
    <div className="main">      
      {renderToast()}
      {renderLayout()}
    </div>
  );
}

export default App;