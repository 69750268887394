import './styles.css';

function Input({ placeholder, type = 'text', name, value, onChange, error}) {
  return (
    <div className="input-container">
      <input 
        className="input-field" 
        type={type} 
        name={name} 
        value={value} 
        onChange={onChange} 
        placeholder={placeholder}
      />
      {error && <span className="input-error-text">{error}</span>}
    </div>
  );
}

export default Input;
