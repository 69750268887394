import './styles.css';
import Input from '../../../components/FormElements/Input';
import Button from '../../../components/FormElements/Button';
import React, { useState} from 'react';
import { useNavigate } from 'react-router-dom';
import VerifyEmailForm from './VerifyEmailForm';
import logo from '../../../Transparent_Logo.svg';

function LogInForm({ logIn }) {
  
  const navigate = useNavigate();
   
  const [userCredentials, setUserCredentials] = useState({ username: '', password: '' });
  
  const [loginFormError, setLogInFormError] = useState('');
  
  const [showVerifyEmail, setShowVerifyEmail] = useState(false); 

  const [isLoading, setIsLoading] = useState(false);

  const updateFormData = (e) => {
    const { name, value } = e.target;
    setUserCredentials({ ...userCredentials, [name]: value });
  };

  const processLogin = async (e) => {
    e.preventDefault();
    setLogInFormError('');
    
    const { username, password } = userCredentials;

    if(!username || !password){
      setLogInFormError('Invalid email or password');
      return;
    }

    setIsLoading(true);

    var logInStatus = await logIn({ username, password });
      
    if(logInStatus === "SUCCESS"){
      setIsLoading(false);
      navigate('/home'); 
    }else if(logInStatus === 'CONFIRM'){
      setIsLoading(false);
      setShowVerifyEmail(true);
    }else if(logInStatus === 'FAILURE'){
      setIsLoading(false);
      setLogInFormError('Invalid email or password');
    }    
  };

  const renderVerifyEmailForm = () => (
    <VerifyEmailForm email={userCredentials.username}></VerifyEmailForm> 
  );

  return (
    <div class="authentication-container">
      <div className="logo-container">
          <img src={logo} alt="Logo" className="logo" />
          <div className="logo-title">SHARKPICK</div>
      </div>
      {!showVerifyEmail ? (
        <div className="form-container">
          <form className="form" onSubmit={processLogin}>
          <label className="form-title">Log In</label>
           <Input 
             name="username"
             placeholder="Enter your email address"
             type="input"
             value={userCredentials.username}
             onChange={updateFormData}
           />
           <Input 
             name="password"
             placeholder="Enter your password"
             type="password"
             value={userCredentials.password}
             onChange={updateFormData}
           />
           <div><a id="forgot-password-link" href="/forgot-password">Forgot Password?</a></div>
           {loginFormError && (
             <div className="error-message">
               <div className="error-text">{loginFormError}</div>
               <button className="close-error" onClick={() => setLogInFormError('')}>
                 &#x2715; 
               </button>
             </div>
           )}
           <div className="button-container">
              <Button text="Log In" isLoading={isLoading}></Button>
           </div>
           <label className="form-label">Don't have an account?</label>
           <div><a className="form-link" href="/sign-up">Sign Up</a></div>
         </form>
       </div>
      ) : (
        // Render the Verify Email Form
        renderVerifyEmailForm()
      )}
    </div>
  );
}

export default LogInForm;
