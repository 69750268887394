import './styles.css';
import Input from '../../../components/FormElements/Input'
import Button from '../../../components/FormElements/Button';
import React, { useState, useEffect } from 'react';
import { signUp }  from 'aws-amplify/auth';
import VerifyEmailForm from './VerifyEmailForm';
import logo from '../../../Transparent_Logo.svg';

function SignUpForm() {
  const [userCredentials, setUserCredentials] = useState({ username: '', password: '', confirmPassword: ''});

  const [emailTouched, setEmailTouched] = useState(false);
  const [emailError, setEmailError] = useState('');

  const [passwordTouched, setPasswordTouched] = useState(false);
  const [passwordError, setPasswordError] = useState('');
  
  const [confirmPasswordTouched, setConfirmPasswordTouched] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState('');

  const [signUpFormError, setSignUpFormError] = useState('');

  const [showVerifyEmail, setShowVerifyEmail] = useState(false); 

   const [isLoading, setIsLoading] = useState(false);
  
  const updateFormData = (e) => {
    const { name, value } = e.target;
    setUserCredentials({ ...userCredentials, [name]: value });

    if(name === 'username'){
      setEmailTouched(true);
    }

    if (name === 'password') {
      setPasswordTouched(true);
    }

    if (name === 'confirmPassword'){
      setConfirmPasswordTouched(true);
    }
  };

  useEffect(() => {
    
    if (emailTouched) {
      const { username } = userCredentials;
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      if(username === ''){
        setEmailError("Email address is required");
      }else if(!emailRegex.test(username)) {
        setEmailError("Email address is invalid");
      }else{
        setEmailError('');
      }
    }

    if (passwordTouched) {
      const { password } = userCredentials;
      const passwordRegex = /^(?=.*[0-9])(?=.*[a-zA-Z]).{8,}$/;
  
      if (!passwordRegex.test(password)) {
        setPasswordError("Min. of 8 characters, 1 letter, 1 number");
      } else {
        setPasswordError('');
      }
    }

    if (confirmPasswordTouched) {
      const { password, confirmPassword } = userCredentials;
    
      if (password !== confirmPassword) {
        setConfirmPasswordError("Passwords do not match");
      } else {
        setConfirmPasswordError('');
      }
    }
  }, [userCredentials, emailTouched, passwordTouched, confirmPasswordTouched]);  

  const processSignUp = async (e) => {
    e.preventDefault();
    setEmailError('');
    setPasswordError('');
    setConfirmPasswordError('');
    
    const { username, password, confirmPassword } = userCredentials;
    
    setIsLoading(true);

    try {

      var error = false;

      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      if(username === ''){
        setEmailError("Email address is required");
        error = true;
      }else if(!emailRegex.test(username)){
        setEmailError("Please enter a valid email address");
        error = true;
      }

      const passwordRegex = /^(?=.*[0-9])(?=.*[a-zA-Z]).{8,}$/;
      if (!passwordRegex.test(password)) {
        setPasswordError("Min. of 8 characters, 1 letter, 1 number");
        error = true;
      }

      if (password !== confirmPassword) {
        setConfirmPasswordError("Passwords do not match");
        error = true;
      }

      if(error){
        setIsLoading(false);
        return;
      }

      if (await signUp({ username, password })) {
        setIsLoading(false);
        setShowVerifyEmail(true);
      }

    } catch (error) {
      switch(error.name){
        case "LimitExceededException":
          setIsLoading(false);
          break;
        case "EmptySignInUsername":
          setIsLoading(false);
          break;
        case "UsernameExistsException":
          setIsLoading(false);
          setEmailError("Email already associated with existing account");
          break;
        default:
          setIsLoading(false);
          setSignUpFormError("Error occured, please try again later");
          console.log(error);
          break;
      }
    }
  };

  const renderVerifyEmailForm = () => (
    <VerifyEmailForm email={userCredentials.username}></VerifyEmailForm> 
  );

  return (
    <div class="authentication-container">
      <div className="logo-container">
          <img src={logo} alt="Logo" className="logo" />
          <div className="logo-title">SHARKPICK</div>
      </div>
      {!showVerifyEmail ? (
      <div className="form-container">
          <form className="form" onSubmit={processSignUp}>
            <label className="form-title">Sign Up</label>
            <Input 
              name="username"
              placeholder="Enter your email address"
              value={userCredentials.username}
              onChange={updateFormData}
              error={emailError}
            />
            <Input 
              name="password"
              placeholder="Enter your password"
              type="password"
              value={userCredentials.password}
              onChange={updateFormData}
              error={passwordError}
            />
            <Input 
              name="confirmPassword"
              placeholder="Re-enter your password"
              type="password"
              value={userCredentials.confirmPassword}
              onChange={updateFormData}
              error={confirmPasswordError}
            />
            {signUpFormError && (
             <div className="error-message">
               <div className="error-text">{signUpFormError}</div>
               <button className="close-error" onClick={() => setSignUpFormError('')}>
                 &#x2715; 
               </button>
             </div>
           )}
            <div className="button-container">
                <Button text="Sign Up" isLoading={isLoading}></Button>
            </div>
            <label className="form-label">Already have an account?</label>
            <a href="log-in" className="form-link">Log In</a>
          </form>
      </div>
    ) : (
      // Render the Verify Email Form
      renderVerifyEmailForm()
    )}
    </div>
  );
}

export default SignUpForm;