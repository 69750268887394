import React, { useState, useEffect } from 'react';
import { fetchImages } from '../../../../../services/articleService';
import './styles.css';

const ImageSelector = ({ currentImage, articleId, onImageUpdate }) => {
  const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(currentImage);
  const [error, setError] = useState('');

  useEffect(() => {
    const loadImages = async () => {
      try {
        const fetchedImages = await fetchImages();
        setImages(fetchedImages);
      } catch (err) {
        setError('Failed to load images');
      }
    };
    loadImages();
  }, []);

  const handleImageSelect = async (image) => {
    setSelectedImage(image);
    onImageUpdate(image);
  };

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  return (
    <div className="image-selector">
      <div className="image-grid">
        {images.map((image, index) => (
          <img
            key={index}
            src={image.url}
            alt={`Option ${index + 1}`}
            className={selectedImage.key === image.key ? 'selected' : ''}
            onClick={() => handleImageSelect(image)}
          />
        ))}
      </div>
    </div>
  );
};

export default ImageSelector;
