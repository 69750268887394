import './styles.css';
import loading from '../../../content/gif/loading.gif';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';

function Button({id, className="default-btn", type="submit", onClick = () => {}, disabled = false, isLoading = false, text, size = "medium", addIcon = false}){
  const getSizeClass = (size) => {
    switch (size) {
        case 'small':
            return 'small-button';
        case 'medium':
            return 'medium-button';
        case 'large':
            return 'large-button';
    }
  };

    return (
      <div>
        <button 
          id={id}
          className={`${className} ${getSizeClass(size)}`} 
          type={type}
          onClick={onClick}
          disabled ={disabled}>
          {isLoading ? (
            <>
                <img src={loading} alt="Loading..." />&nbsp;
            </>
          ) : (
            <>
              {addIcon && <FontAwesomeIcon icon={faPlusCircle} />}
              {addIcon && "\u00A0"}
            </>
          )}
          {text}
        </button>
      </div>
    );
}

export default Button;