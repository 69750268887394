import React, { useEffect, useState } from 'react'; 
import './styles.css';
import ArticlePane from './ArticlePane'
import { fetchArticles } from '../../services/articleService.js'
// import { deleteArticleById } from '../../services/articleService.js'
// import { updateArticleContentById } from '../../services/articleService.js';
// import { addBlankArticle } from '../../services/articleService.js';

const Home = () => {
    const [articles, setArticles] = useState([]);

    //updateArticleContentById("648deba3-f223-42e0-b8e3-058f7e7bfdc4",false);
    //deleteArticleById("d7088422-8dfe-4f34-8bfd-0c31cda46f76");
    //addBlankArticle();

    useEffect(() => {
      const loadArticles = async () => {
        const articlesFromAPI = await fetchArticles(true);
        setArticles(articlesFromAPI);
      };

      loadArticles();
    }, []);
    
    return (
    <>
      <div className="home">
          <div className="article-container"> 
              {articles.map(article => (
                < ArticlePane key={article.id} article={article} imageUrl={article.imageUrl} />
              ))}
          </div>
      </div>
    </>
  );
};

export default Home;