import {Route, Routes} from 'react-router-dom';
import './styles.css'
import Home from '../../pages/Home';
import Article from '../../pages/Article';
import UploadArticleImage from './Forms/UploadArticleImage';
import ManageArticles from './Pages/ManageArticles';
import ManageArticle from './Pages/ManageArticle';
import Navbar from '../../components/Navbar';

const Admin = ({isUserAuthenticated, userGroup, logOut}) => {
  
  const renderNavbar = () => {
    return <Navbar 
        isUserAuthenticated={isUserAuthenticated} 
        userGroup={userGroup} 
        logOut={logOut} 
    />
  }

  return (
    <>
      {renderNavbar()}
      <div className="admin-container">   
          <Routes>
            <Route path="*" element={<Home isAuthenticated={true} logOut={logOut} />} />
            <Route path="/articles/:type/:title" element={<Article />} />
            <Route path="/manage/articles/:type/:title" element={<ManageArticle />} />
            <Route path="/manage/articles" element={<ManageArticles />} /> 
            <Route path="/manage/upload-image" element={<UploadArticleImage />} /> 
          </Routes>
      </div>
    </>
  );
};

export default Admin;