import React, { useState, useRef, useEffect } from 'react';
import './styles.css';
import logo from '../../Logo.svg';
import Button from '../../components/FormElements/Button';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css'; 
import 'bootstrap/dist/js/bootstrap.bundle.min';

const Navbar = ({ isUserAuthenticated, userGroup, logOut }) => {

    const navAuthActionText = isUserAuthenticated ? 'Sign Out' : 'Log In';
    const showAdminOptions = isUserAuthenticated && userGroup === 'admin';

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);

    const navigate = useNavigate();

    const toggleNavbar = () => {
      const navbarCollapse = document.getElementById("navbarNav");
      const navbarToggler = document.querySelector(".navbar-toggler");
    
      // Toggle the "show" class on the navbarCollapse element
      navbarCollapse.classList.toggle("show");
    
      // Toggle the aria-expanded attribute on the navbarToggler button
      navbarToggler.setAttribute("aria-expanded", !navbarToggler.getAttribute("aria-expanded"));
    };

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const uploadImage = () => {
        toggleNavbar();
        navigate('/manage/upload-image');
    };
    
    const articlesPendingApproval = () => {
        toggleNavbar(); 
        navigate('/manage/articles');
    };

    const handleAuthClick = () => {
        toggleNavbar();
        if (isUserAuthenticated) {
          logOut();
        } else {
          navigate('/log-in');
        }
    };

    useEffect(() => {
      // Close the dropdown if clicked outside
      function handleClickOutside(event) {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target) && event.target.id !== "nav-button") {
          setIsDropdownOpen(false);
        } 
      }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
        document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownRef]);

    return (   
     <nav className="navbar navbar-expand-lg">
        <div className="container">
          <a className="navbar-brand" href="/">
            <img src={logo} alt="Logo" className="logo" />
            <div className="logo-title">SHARKPICK</div>
          </a>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav ms-auto">
            {showAdminOptions &&
              <li className="nav-item dropdown" ref={dropdownRef}>
                <button 
                  className="nav-link dropdown-toggle" 
                  id="navbarDropdown" 
                  role="button" 
                  aria-expanded={isDropdownOpen} 
                  onClick={toggleDropdown}
                >
                  Manage
                </button>
                {isDropdownOpen && (
                 <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                    <li><button className="dropdown-item" onClick={articlesPendingApproval}>Articles</button></li>
                    <li><button className="dropdown-item" onClick={uploadImage}>Upload Image</button></li>
                 </ul>
                )}
              </li>
              }
              <li className="nav-item nav-item-btn">
                  <Button id="nav-button" text={navAuthActionText} onClick={handleAuthClick} size="large"></Button>
              </li>
            </ul>
          </div>
        </div>     
      </nav>     
    );
};

export default Navbar;