import React, { useState } from 'react';
import { uploadData } from 'aws-amplify/storage';
import './UploadArticleImage.css';
import Button from '../../../components/FormElements/Button'

const UploadArticleImage = () => {
  const [file, setFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState('');
  const [uploadMessage, setUploadMessage] = useState('');

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const uploadDataInBrowser = async () => {
    if (!file) {
      setUploadStatus('error');
      setUploadMessage('No file selected');
      return;
    }

    const filename = file.name;

    if (!file.type.startsWith('image/')) {
      setUploadStatus('error');
      setUploadMessage('File is not an image');
      return;
    }

    try {
      await uploadData({
        key: filename,
        data: file
      });
      setUploadStatus('success');
      setUploadMessage('File uploaded successfully');
    } catch (error) {
      setUploadStatus('error');
      setUploadMessage(`Error uploading file: ${error.message}`);
    }
  };

  return (
     <div className="upload-wrapper">
      <div className="upload-container">
        <h2>Upload Image</h2>
        <input
          type="file"
          accept="image/png, image/jpeg, image/gif"
          onChange={handleFileChange}
          className="upload-input"
        />
        <Button text="Upload" onClick={uploadDataInBrowser} size="large"></Button>
        {uploadStatus && (
          <div className={`message ${uploadStatus}`}>
            {uploadMessage}
          </div>
        )}
      </div>
    </div>
  );
}

export default UploadArticleImage;
