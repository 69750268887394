/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_appsync_graphqlEndpoint": "https://ooyfbviayndcjeeigfisps77pi.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-tnzzh7kz6vha7hyheb3gslooae",
    "aws_cognito_identity_pool_id": "us-east-2:643134cf-c87e-41c3-bc9c-7fef5c94bf1d",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_JLHaKv19m",
    "aws_user_pools_web_client_id": "79302pdk5rafi1a65sq1fk2jq2",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "images221336-prod",
    "aws_user_files_s3_bucket_region": "us-east-2"
};


export default awsmobile;
